<!--露呗后台-->
<template>
  <div class="box">
    <div class="box_left">
      <div class="top">
        <div class="statistical_time">
          <div class="time">统计时间：{{ time }}</div>
          <div class="option">
            <div :class="timeType == 0 ? 'color1' : ''" @click="selecTime(0)">
              昨天
            </div>
            <div :class="timeType == 1 ? 'color1' : ''" @click="selecTime(1)">
              本周
            </div>
            <div :class="timeType == 2 ? 'color1' : ''" @click="selecTime(2)">
              本月
            </div>
            <div :class="timeType == 3 ? 'color1' : ''" @click="selecTime(3)">
              本年
            </div>
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) in list" :key="index" class="list_style">
            <div class="text">{{ item.num }}</div>
            <div class="text2">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="middle_top">
          <div class="week">
            <el-date-picker
              v-model="value1"
              type="week"
              format="yyyy 第 WW 周"
              :picker-options="{
                firstDayOfWeek: 1
              }"
              @change="changeWeek($event)"
            >
            </el-date-picker>
            <div class="week_time" :class="weekTime ? '' : 'color2'">
              {{ weekTime || "选择周" }}
            </div>
          </div>
        </div>
        <div class="table">
          <div class="table_chart" id="main"></div>
        </div>
      </div>
      <div class="middle">
        <div class="middle_top">
          <div class="month">
            <el-date-picker
              v-model="value3"
              type="year"
              @change="changeYear($event)"
            >
            </el-date-picker>
          </div>
          <div class="year_time">{{ yearTime+'年' || "选择年份" }}</div>
        </div>
        <div class="table">
          <div class="table_chart" id="main2"></div>
        </div>
      </div>
    </div>
    <div class="box_right">
      <div class="top">
        <div class="title">产品排名</div>
        <div class="option_box">
          <div :class="rankingType ? '' : 'color1'" @click="seeRank(0)">
            本周
          </div>
          <div :class="rankingType ? 'color1' : ''" @click="seeRank(1)">
            本月
          </div>
        </div>
      </div>
      <div class="content">
        <div class="header">
          <div class="th">排名</div>
          <div class="th2">营地名称</div>
          <div class="th3">下单数量</div>
          <div class="th4">新增用户数量</div>
          <div class="th4">订单金额总计</div>
        </div>
        <div class="list_content">
          <div class="list" v-for="(item, index) in rankingList" :key="index">
            <div class="th" v-if="index < 3">
              <img
                :src="require(`../../assets/dataStatistics/${index + 1}.png`)"
                alt=""
              />
            </div>
            <div class="th" v-else>
              <div class="bg">{{ index + 1 }}</div>
            </div>
            <div class="th2">{{ item.name }}</div>
            <div class="th3">{{ item.orders }}</div>
            <div class="th4">{{ item.people }}</div>
            <div class="th4">{{ item.money }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  luBeiRank,
  luBeiTotal,
  histogramData,
  brokenLineData,
} from "../../api/dataStatistics.js";
import * as echarts from "echarts";

export default {
  data() {
    return {
      firstDayOfWeek: 1,
      time: "",
      value1: "",
      value2: "",
      value3: "",
      yearTime: "",
      weekTime: "", //选择的周
      list: [
        {
          num: 0,
          title: "新增注册用户数",
        },
        {
          num: 0,
          title: "新增订单数量",
        },
        {
          num: 0,
          title: "新增订单金额",
        },
        {
          num: 0,
          title: "注册用户总数",
        },
        {
          num: 0,
          title: "订单数量总计",
        },
        {
          num: 0,
          title: "订单金额总计",
        },
        {
          num: 0,
          title: "营地总数",
        },
      ],
      timeType: 0, //0:昨天；1：本周；2：本月；3：本年；
      rankingType: 0, //0：选择本周；1：选择本月
      rankingList: [],
    };
  },
  beforeMount() {
    let dates = new Date();
    let month2 = dates.getMonth() + 1;
    let date2 = dates.getDate();
    if (month2 < 10) {
      month2 = "0" + month2;
    }
    if (date2 < 10) {
      date2 = "0" + date2;
    }
    let times = dates.getFullYear() + "-" + month2 + "-" + date2 + " 08:00:00";
    this.yearTime = dates.getFullYear();
    // console.log(this.yearTime, "date");
    this.gethistogramData(this.yearTime);
    this.getBrokenLineData(times);
  },
  mounted() {
    this.histogram();
    this.getluBeiRank();
    this.getTotal();
  },
  methods: {
    //选择年份
    changeYear(e) {
      console.log(e);
      this.yearTime = e.getFullYear();
      this.gethistogramData(this.yearTime);
    },
    //获取柱状图数据
    async gethistogramData(year) {
      let that = this;
      let res = await histogramData({
        year: year,
      });
      if (res.ResultCode == 0) {
        console.log(res, "res");
        let num = 0;
        let moneyList = [];
        let sumList = [];
        let userList = [];
        for (let i in res.Data.money) {
          moneyList[num] = {};
          moneyList[num] = res.Data.money[i];
          num++;
        }
        for (let i in res.Data.sum) {
          sumList[num] = {};
          sumList[num] = res.Data.sum[i];
          num++;
        }
        for (let i in res.Data.user) {
          userList[num] = {};
          userList[num] = res.Data.user[i];
          num++;
        }

        that.histogram(moneyList, sumList, userList);
      }
    },
    //选择周
    changeWeek(e) {
      console.log(e, '8978')
      let month2 = e.getMonth() + 1;
      let date2 = e.getDate();
      if (month2 < 10) {
        month2 = "0" + month2;
      }
      if (date2 < 10) {
        date2 = "0" + date2;
      }
      let times = e.getFullYear() + "-" + month2 + "-" + date2 + " 08:00:00";
      console.log(times)
      this.weekTime =
        e.getFullYear() +
        "/" +
        (e.getMonth() + 1) +
        "/" +
        (e.getDate() - 1) +
        "-" +
        e.getFullYear() +
        "/" +
        (e.getMonth() + 1) +
        "/" +
        (e.getDate() + 5);
      console.log(this.weekTime, 'weekTime')
      this.getBrokenLineData(times);
    },
    //获取折线图数据
    async getBrokenLineData(createTime) {
      let that = this;
      let res = await brokenLineData({
        createTime: createTime,
      });
      if (res.ResultCode == 0) {
        let num = 0;
        let moneyList = [];
        let sumList = [];
        let userList = [];
        that.weekTime = res.Data.week1.a.replaceAll('-','/')+'-'+res.Data.week1.g.replaceAll('-','/')
        for (let i in res.Data.money) {
          moneyList[num] = {};
          moneyList[num] = res.Data.money[i];
          num++;
        }
        for (let i in res.Data.sum) {
          sumList[num] = {};
          sumList[num] = res.Data.sum[i];
          num++;
        }
        for (let i in res.Data.user) {
          userList[num] = {};
          userList[num] = res.Data.user[i];
          num++;
        }
        let zr = this.getSevenDay(createTime)
        let starT = createTime.split(' ')[0]
        let dates = this.formatEveryDay(starT, zr)
        console.log(starT)
        let arr2 = []
          for(let j=0;j<dates.length;j++){
            arr2.push(`${dates[j]}(${this.getDay(new Date(dates[j]).getDay())})`)
          }
        that.weekTime = dates[0] + '/' + dates[dates.length-1]
        that.brokenLine(moneyList, sumList, userList, arr2);
      }
    },
    getDay(data) {
      switch (data) {
        case 0:
          return `周日`;
          // eslint-disable-next-line no-unreachable
          break;
        case 1:
          return `周一`;
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return `周二`;
          // eslint-disable-next-line no-unreachable
          break;
        case 3:
          return `周三`;
          // eslint-disable-next-line no-unreachable
          break;
        case 4:
          return `周四`;
          // eslint-disable-next-line no-unreachable
          break;
        case 5:
          return `周五`;
          // eslint-disable-next-line no-unreachable
          break;
        case 6:
          return `周六`;
          // eslint-disable-next-line no-unreachable
          break;
      }
    },
    formatEveryDay(start, end) {
      let dateList = [];
      var startTime = this.getDate(start);
      var endTime = this.getDate(end);

      while ((endTime.getTime() - startTime.getTime()) >= 0) {
        var year = startTime.getFullYear();
        var month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1;
        var day = startTime.getDate().toString().length == 1 ? "0" + startTime.getDate() : startTime.getDate();
        dateList.push(year + "-" + month + "-" + day);
        startTime.setDate(startTime.getDate() + 1);
      }
      return dateList;
    },
    getDate(datestr) {
      var temp = datestr.split("-");
      var date = new Date(temp[0], temp[1] - 1, temp[2]);
      return date;
    },
    getSevenDay(date){
      //
      let today = new Date(date);
      let seven_milliseconds = today.getTime()+1000*60*60*24*6;
      let sevenDay = new Date();
      sevenDay.setTime(seven_milliseconds);

      let strYear=sevenDay.getFullYear();
      let strDay=sevenDay.getDate();
      let strMonth=sevenDay.getMonth()+1;
      if(strMonth<10)
      {
        strMonth="0"+strMonth;
      }
      if (strDay<10){

        strDay = "0"+strDay;
      }
      let strSevenDay=strYear+"-"+strMonth+"-"+strDay;
      return strSevenDay
    },
    //
    selecTime(index) {
      this.timeType = index;
      this.getTotal();
    },
    //根据选择的时间获取统计数据
    async getTotal() {
      let that = this;
      let _date = "";
      switch (that.timeType) {
        case 0:
          _date = "yesterday";
          break;
        case 1:
          _date = "week";
          break;
        case 2:
          _date = "month";
          break;
        case 3:
          _date = "year";
          break;
      }
      let res = await luBeiTotal({
        date: _date,
      });
      if (res.ResultCode == 0) {
        that.time = res.Data[0];
        that.list.forEach((tmp, index) => {
          tmp.num = res.Data[index + 1];
        });
      }
    },
    //查看月份或者周的排名
    seeRank(index) {
      this.rankingType = index;
      this.getluBeiRank();
    },
    //获取排序数据
    async getluBeiRank() {
      let _week = "";
      let that = this;
      if (that.rankingType) {
        _week = "month";
      } else {
        _week = "week";
      }
      let res = await luBeiRank({
        my: _week,
      });
      if (res.ResultCode == 0) {
        that.rankingList = res.Data;
      }
    },
    //制作折线图
    brokenLine(list, list2, list3, arr2) {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        grid: {
          left: 60,
          right: 60,
          top: 60,
          bottom: 50,
        },
        legend: {
          itemHeight: 4,
          icon: "rectangle",
          data: ["订单金额", "订单数量", "用户数量"],
          orient: "horizontal",
          x: "right",
          y: " center",
          padding: [5, 20, 0, 0],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月"],
          data: arr2,
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              // color: '#912CEE'
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: list,
            type: "line",
            name: "订单金额",
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#2DC0DE",
                    },
                    {
                      offset: 1,
                      color: "rgba(45, 192, 222, 0)",
                    },
                  ],
                  false
                ),
              },
            },
            smooth: 0.5,
            symbolSize: 0,
            lineStyle: {
              width: 1, // 设置线宽
              type: "solid", //'dotted'虚线 'solid'实线
            },
          },
          {
            data: list2,
            type: "line",
            name: "订单数量",
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#348DF0",
                    },
                    {
                      offset: 1,
                      color: "rgba(126,171,233,0)",
                    },
                  ],
                  false
                ),
              },
            },
            symbolSize: 0,
            smooth: 0.5,
            lineStyle: {
              width: 1, // 设置线宽
              type: "solid", //'dotted'虚线 'solid'实线
            },
          },
          {
            data: list3,
            type: "line",
            name: "用户数量",
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#348DF0",
                    },
                    {
                      offset: 1,
                      color: "rgba(72, 184, 240, 0)",
                    },
                  ],
                  false
                ),
              },
            },
            symbolSize: 0,
            smooth: 0.5,
            lineStyle: {
              width: 1, // 设置线宽
              type: "solid", //'dotted'虚线 'solid'实线
            },
          },
        ],
        color: ["#2DC0DE", "#348DF0", "#48B8F0"],
      };

      option && myChart.setOption(option);
    },
    //制作柱状图
    histogram(list, list2, list3) {
      var chartDom = document.getElementById("main2");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          left: 40,
          right: 40,
          top: 60,
          bottom: 30,
        },
        legend: {
          itemHeight: 15,
          icon: "rectangle",
          data: ["订单金额", "订单数量", "用户数量"],
          orient: "horizontal",
          x: "right",
          y: " center",
          padding: [10, 20, 0, 0],
        },
        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: list,
            type: "bar",
            name: "订单金额",
            itemStyle: {
              borderRadius: 4,
            },
          },
          {
            data: list2,
            type: "bar",
            name: "订单数量",
            itemStyle: {
              borderRadius: 4,
            },
          },
          {
            data: list3,
            type: "bar",
            name: "用户数量",
            itemStyle: {
              borderRadius: 4,
            },
          },
        ],
        color: ["#2DC0DE", "#62A5FC", "#48B8F0"],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 63.19rem;
  background: #f5f6f7;
  display: flex;
  .box_left {
    flex: 1.3;
    padding-left: 1.19rem;
    padding-top: 1.25rem;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 13rem;
      background: #ffffff;
      box-sizing: border-box;
      padding-top: 2.19rem;
      padding-left: 1.44rem;
      padding-right: 1.06rem;
      .statistical_time {
        display: flex;
        .time {
          font-size: 1.13rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
        .option {
          margin-left: 3.31rem;
          font-size: 1.13rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          div {
            display: inline-block;
            margin-right: 1.5rem;
            cursor: pointer;
          }
        }
      }
      .content {
        display: flex;
        margin-top: 2.25rem;
        justify-content: space-between;
        .list_style {
          width: 8.25rem;
          height: 4.88rem;
          background: #f3f9ff;
          border: 1px solid #1492ff;
          border-radius: 1rem;
          box-sizing: border-box;
          padding-left: 0.81rem;
          .text {
            font-size: 1.75rem;
            font-family: DIN Alternate;
            font-weight: bold;
            color: #333333;
            margin-top: 0.5rem;
          }
          .text2 {
            font-size: 0.88rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }
        }
      }
    }
    .middle {
      width: 100%;
      height: 22.88rem;
      background: #ffffff;
      margin-top: 1.25rem;
      box-sizing: border-box;
      padding-top: 0.94rem;
      padding-left: 1.56rem;
      .middle_top {
        display: flex;
        position: relative;
        .year_time {
          position: absolute;
          width: 4.5rem;
          height: 2.13rem;
          font-size: 1.13rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          background: #ffffff;
          line-height: 2rem;
          text-align: center;
          margin-left: 2px;
          margin-top: 1px;
        }
        .month {
          width: 12.5rem;
          height: 2.13rem;
          border: 1px solid #cccccc;
          border-radius: 0.25rem;
          background-image: url("../../assets/dataStatistics/12.png");
          background-repeat: no-repeat;
          background-size: 1.38rem 1.38rem;
          background-position-y: 0.25rem;
          background-position-x: 10.5rem;
        }
        .week {
          // margin-left: 1.56rem;
          width: 18rem;
          height: 2.13rem;
          border: 1px solid #cccccc;
          border-radius: 0.25rem;
          background-image: url("../../assets/dataStatistics/13.png");
          background-repeat: no-repeat;
          background-size: 1.38rem 1.38rem;
          background-position-y: 0.25rem;
          background-position-x: 16.25rem;
          position: relative;
          .week_time {
            position: absolute;
            top: 0.3125rem;
            left: 0.88rem;
            font-size: 1.13rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            background-color: #ffffff;
          }
        }
      }
      .table {
        width: 100%;
        height: 19.81rem;
        // margin-top: -1.25rem;
        .table_chart {
          width: 100%;
          height: 19.81rem;
        }
      }
    }
    .bom {
      width: 100%;
      height: 22.81rem;
      background: #ffffff;
      margin-top: 1.25rem;
    }
  }
  .box_right {
    flex: 0.7;
    margin: 0 1.25rem;
    box-sizing: border-box;
    margin-top: 1.25rem;
    padding-top: 2.19rem;
    width: 100%;
    height: 61.25rem;
    background: #ffffff;
    .top {
      display: flex;
      font-size: 1.13rem;
      font-family: PingFang SC;
      font-weight: 500;
      .title {
        margin-left: 1.31rem;
      }
      .option_box {
        margin-left: 1.63rem;
        div {
          display: inline-block;
          margin: 0 1rem;
          cursor: pointer;
        }
      }
    }
    .content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.63rem;
      margin-top: 2.5rem;
      .header {
        background: #f3f9ff;
      }
      .header,
      .list {
        width: 100%;
        height: 3.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          text-align: center;
        }
        .th {
          flex: 0.7;
          img {
            width: 1.63rem;
            height: 1.63rem;
          }
        }
        .bg {
          width: 1.63rem;
          height: 1.63rem;
          background: #2ec0de;
          border-radius: 50%;
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #fefefe;
          margin: 0 auto;
          text-align: center;
          line-height: 1.63rem;
        }
        .th2 {
          flex: 1.1;
        }
        .th3 {
          flex: 1.1;
        }
        .th4 {
          flex: 1.1;
        }
      }
      .list_content {
        width: 100%;
        height: 51.69rem;
        overflow-y: auto;
      }
    }
  }
}
.color1 {
  color: #348df0;
}
.color2 {
  color: #999999 !important;
}
.month ::v-deep .el-input__inner {
  border: none !important;
  height: 2.13rem !important;
  background-color: rgba($color: #000000, $alpha: 0);
  font-size: 1.13rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  width: 100% !important;
  cursor: pointer !important;
}
.month ::v-deep .el-input__icon {
  display: none !important;
}
.month ::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
.month ::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.88rem !important;
}
.week ::v-deep .el-input__inner {
  border: none !important;
  height: 2.13rem !important;
  background-color: rgba($color: #000000, $alpha: 0);
  width: 100% !important;
  cursor: pointer !important;
}
.week ::v-deep .el-input__icon {
  display: none !important;
}
.week ::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
.week ::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.88rem !important;
}
</style>
